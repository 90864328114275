import classNames from 'classnames';
import Portal from 'components/Portal';
import { TOOLTIP_THEMES } from 'config/constants';
import React, { useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { classModifier } from 'utils';
import { CSSTransition } from 'react-transition-group';

import "./Tooltip.scss";

const Tooltip = React.forwardRef(({
  className,
  theme = TOOLTIP_THEMES.GENERAL,
  text,
  visibleWhenIsOverflowed = false,
  placement = 'top',
  extraCondition = true,
  disable,
  children,
  interactive,
}, ref) => {
  const {
    getTooltipProps,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    state,
  } = usePopperTooltip({
    trigger: 'hover',
    placement,
    interactive,
    offset: interactive ? [0, -1] : [0, 0],
  });

  const [isExtraVisible, setIsExtraVisible] = useState(!visibleWhenIsOverflowed);

  const setCallbackTriggerRef = (node) => {
    const isOverflowedWidth = node?.offsetWidth < node?.scrollWidth;
    const isOverflowedHeight = node?.offsetHeight + 2 < node?.scrollHeight;

    if (isOverflowedHeight || isOverflowedWidth) {
      setIsExtraVisible(true)
    }

    node && setTriggerRef(node);
  }

  const setRefs = (node) => {
    setCallbackTriggerRef(node);

    if (ref) {
      ref.current = node;
    }
  }

  const classes = classNames(
    classModifier('tooltip', [theme, state?.placement]),
    className,
  );

  const isTooltipVisible = isExtraVisible && visible && extraCondition && !disable && text;

  return (
    <>
      {children instanceof Function
      	? children(setCallbackTriggerRef)
        : (React.cloneElement(React.Children.only(children), { ref: setRefs }))}

      <CSSTransition
        in={isTooltipVisible}
        timeout={100}
        classNames="tooltip"
        unmountOnExit
      >
        <Portal>
          <div
            {...getTooltipProps({
              ref: setTooltipRef,
              className: classes,
              onClick: (e) => e.stopPropagation(),
            })}
          >
            {text instanceof Function ? text() : text}

            <div className={classModifier('tooltip__arrow', theme)} {...getArrowProps()} />
          </div>
        </Portal>
      </CSSTransition>
    </>
  )
});

export default Tooltip;
