import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import API from 'api/api';
import ICONS from 'assets/icons';
import { BOOKING_STATUS_FILTERS, TOOLTIP_THEMES } from 'config/constants';
import { classModifier } from 'utils';
import { selectOperatorById } from 'redux/selectors/selectors';

import Tooltip from 'components/UI/Tooltip/Tooltip';


const COUNTERS = [
  {
    value: 'bookingsCountForMonth',
    text: 'All Bookings in ',
    color: '#BFBFBF',
  },
  {
    value: 'done',
    text: 'Done',
    color: '#0092F2',
  },
  {
    value: 'cancelled',
    text: 'Canceled',
    color: '#005947',
  },
];


const TooltipContent = ({ operatorIds }) => {
  const OperatorName = ({ id }) => {
    const operator = useSelector((state) => selectOperatorById(state, id));

    return <p>{operator ? operator.username : '[DELETED]'}</p>
  }

  return (
    <div className="calendar-split__tooltip-content">
      <ICONS.operator />
      {operatorIds.map((id) => <OperatorName key={id} id={id} />)}
    </div>
  )
}


const SplitBookingsCalendar = ({ currentDate, setDate, date, pending }) => {
  const initialCounters = {
    bookingsCountForMonth: '0',
    cancelled: '0',
    done: '0',
  };

  const [loading, setLoading] = useState(true);
  const [calendar, setCalendar] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);
  const [counters, setCounters] = useState(initialCounters);
  const [weekdays, setWeekdays] = useState([]);

  useEffect(() => {
    const startDate = Array.isArray(date) ? date[0] : date;
    const calendarDate = moment(startDate).startOf('month').valueOf();

    setCalendarDate(calendarDate);
  }, [date, pending])

  useEffect(() => {
    if (calendarDate === null) return;

    const dateParam = new Date(calendarDate).setHours(12, 0, 0);

    API.getBookingsByMonth(dateParam)
      .then(({ data }) => {
        const { calendar, bookingsCountForMonth, cancelled, done, daysWeekHeader } = data;

        setCalendar(calendar);
        setWeekdays(daysWeekHeader);
        setCounters({ bookingsCountForMonth, cancelled, done });
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [calendarDate]);

  const changeCalendarDate = (day) => {
    if (loading) return;

    setCalendarDate((prevState) => {
      let updatedDate = new Date(prevState);

      switch (day) {
        case 'next':
          updatedDate.setMonth(updatedDate.getMonth() + 1);
          break;
        case 'prev':
          updatedDate.setMonth(updatedDate.getMonth() - 1);
          break;
        case 'current':
          return currentDate;
      }

      return updatedDate.getTime();
    });
  };

  const getCalendarMonth = (date, withYear = false) => {
    return date && new Date(date).toLocaleString('en-US', {
      month: 'long',
      ...(withYear ? { year: 'numeric', } : {}),
    });
  };

  const getDateParam = (date, param = 'month', value = 'long') => {
    return new Date(date).toLocaleString('en-US', { [param]: value });
  };

  const isDayActive = (day) => {
    if (Array.isArray(date)) {
      return (
        new Date(day).getTime() >= new Date(date[0]).setHours(0, 0, 0).valueOf()
        && new Date(day).setHours(0, 0, 0) <= new Date(date[1]).setHours(23, 59, 59).valueOf()
      );
    } else {
      if (getDateParam(date) === getDateParam(day)) {
        return (
          getDateParam(date, 'day', 'numeric')
          ===
          getDateParam(day, 'day', 'numeric')
        );
      }
    }
  };


  return (
    <div className="calendar-split__calendar-wrap">
      <div className="calendar-split__calendar">
        <div className="calendar-split__calendar-controller">
          <ICONS.angleQuote
            onClick={() => changeCalendarDate('prev')}
            className="calendar-split__calendar-controller-icon"
          />
          {getCalendarMonth(calendarDate, true)}

          <ICONS.angleQuote
            onClick={() => changeCalendarDate('next')}
            className={classModifier('calendar-split__calendar-controller-icon', 'next')}
          />
        </div>

        <div className="calendar-split__calendar-weekdays">
          {weekdays.map((day) => (
            <div
              key={day}
              className="calendar-split__calendar-weekdays-item"
            >
              {day}
            </div>
          ))}
        </div>

        <div className="calendar-split__calendar-days-list">
          {calendar && Object.keys(calendar).map((day) => {
            const { user_ids, ...counters } = calendar[day];

            return (
              <Tooltip
                text={<TooltipContent operatorIds={user_ids}/>}
                theme={TOOLTIP_THEMES.SEMIDARK}
                extraCondition={user_ids.length > 0}
              >
                <div
                  key={day}
                  className={classModifier('calendar-split__calendar-day', [
                    getDateParam(day) !== getDateParam(calendarDate) && 'hidden',
                    isDayActive(day) && 'active',
                  ])}
                  onClick={() => setDate(new Date(day).getTime())}
                >
                  <div className="calendar-split__calendar-day-number">
                    {new Date(day).toLocaleString('en-US', { day: 'numeric' })}
                  </div>

                  <div className="calendar-split__calendar-day-counters">
                    {Object.keys(counters).map((counterName) => (
                      <div key={counterName} className="calendar-split__calendar-day-counter">
                        <span
                          className='calendar-split__calendar-day-dot'
                          style={{ backgroundColor: BOOKING_STATUS_FILTERS[counterName.split(' ').join('')].color }}
                        />

                        {counters[counterName]}
                      </div>
                    ))}
                  </div>
                </div>
              </Tooltip>
            )
          })}
        </div>
      </div>

      <div className="calendar-split__counters-wrap">
        <div className="calendar-split__counters">
          {COUNTERS.map((counter) => (
            <div
              key={counter.value}
              style={{ borderColor: counter.color }}
              className={classModifier('calendar-split__counter', [
                counter.value === 'bookingsCountForMonth' ? 'main' : 'additional',
              ])}
            >
              {counter.text}
              {counter.value === 'bookingsCountForMonth' && getCalendarMonth(calendarDate)} - {counters[counter.value]}

              <span
                className='calendar-split__counter-dot'
                style={{ backgroundColor: counter.color }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SplitBookingsCalendar;
