import React, { Fragment, Suspense, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { closeModal, closeChildModal } from 'redux/ducks/activeWindows';

import './Dialogs.scss';
import Modal from 'components/Modal/Modal';
import DelayedComponent from 'components/DelayedComponent/DelayedComponent';
import flattenByProperty from 'utils/flattenByProperty';

const DelayedComponents = (props) => {
  const {
    activeModals = [],
  } = props;

  const dispatchCloseEvent = (props) => {
    const event = new CustomEvent("closeModal", { detail: props })
    document.dispatchEvent(event);
  };

  return (
    <>
      {ReactDOM.createPortal(
        <Fragment>
          <Suspense fallback={<div className='suspense' />}>
            {activeModals.map((modal, i, arr) =>
              <DelayedComponent isMount={modal.isShow} key={modal.type}>
                <Modal
                  isOpen={modal.isShow}
                  modalType={modal.type}
                  closeChildModal={props.closeChildModal}
                  closeModal={props.closeModal}
                  notCloseOutside={!modal.isCloseOutside}
                  notCloseOnEscape={!modal.isCloseOnEscape}
                  dispatchCloseEvent={dispatchCloseEvent}
                  backgroundClassModifier={modal.backgroundClassModifier}
                  isLastModal={i === arr.length - 1}
                  childCount={modal.childCount}
                  commonProps={modal.props?.commonProps}
                >
                  {flattenByProperty(modal, (modal) => modal.props?.child)}
                </Modal>
              </DelayedComponent>
            )}
          </Suspense>
        </Fragment>,
        document.getElementById('dialogs-root')
      )}
    </>
  );
};

const mapStateToProps = state => ({
  activeModals: state.activeWindows.activeModals,
});

const mapDispatchToProps = {
  closeModal,
  closeChildModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DelayedComponents);
