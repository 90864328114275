import LocationPreview from 'components/LocationPreview/LocationPreview';
import NavigationPreview from 'components/NavigationPreview/NavigationPreview';

const AttachemntMapPreview = ({ location, route }) => {
  const { origin, destination } = route || {};

  return (
    <article className="attachment-map">
      {route ? (
        <NavigationPreview origin={origin} destination={destination} />
      ) : (
        <LocationPreview location={location} />
      )}
    </article>
  )
}

export default AttachemntMapPreview;
