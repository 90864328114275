import { useState } from "react";

import { DIVA_GIRLS_LINK } from "redux/ducks/divaGirls";

import ICONS from "assets/icons";
import ProfileRates from "components/ProfileRates/ProfileRates";

const ProfileCardHeader = (props) => {
  const {
    profile
  } = props;

  const [isRatesOpen, setIsRatesOpen] = useState(false);

  return (
    <div className="profile-card__header">
      {profile.link &&
        <a
          href={DIVA_GIRLS_LINK + profile.link}
          target="_blank"
          className="profile-card__header-btn"
        >
          <ICONS.externalLinkSquare width={14} height={14} />
        </a>
      }

      <button 
        className="profile-card__header-btn"
        onMouseEnter={() => setIsRatesOpen(true)}
        onMouseLeave={() => setIsRatesOpen(false)}
      >
        <ICONS.poundSign className="profile-card__header-btn-icon" width={13} height={13} />

        {isRatesOpen &&
          <div className="profile-card__header-rates">
            <ProfileRates
              isEditor
              profileId={profile.id}
              isMember={profile.is_for_member || false}
              defaultPrices={profile.prices || []}
              memberPrices={profile.prices_member || []}
              secretPrices={profile.prices_secret || []}
            />
          </div>
        }
      </button>
    </div>
  )
};

export default ProfileCardHeader;
